import React, { useContext } from "react";
import {
  FilterDescription,
  QueryDescription,
  SyntheticFieldDescription,
  TABLE_NAMES,
} from "common/utils/queryBuilder";
import { NetworkStatus } from "@apollo/client";
import { ColumnDef } from "@tanstack/table-core/build/lib/types";
import { useLocation } from "react-router";
import { SUBMISSION_CATEGORY } from "common/constants";

import {
  GetQuerySubmissionsQueryVariables,
  useGetQuerySubmissionsLazyQuery,
  useGetSubmissionTableConfigQuery,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../Common/Tables/hooks";
import { FullWidthTable } from "../Common/FullWidthTable/FullWidthTable";
import { QuerySubmissionTableResult } from "./__queries__/table";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../hooks/useTableDisplayConfig";
import {
  fieldId,
  mergeFieldGroups,
  sanityCheckLocalColumnOrder,
} from "../Common/FullWidthTable/utils";
import { Attribute } from "../Common/FullWidthTable/types";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../Common/ActionCell";
import ActionButton from "./ActionButton";
import ExportDataButton from "../Exports/ExportDataButton";
import { FILES_TABLE_CUSTOM_CELLS } from "../DocumentUploads/FilesTable";

const SUBMISSIONS_CATEGORY_MAP = {
  [SUBMISSION_CATEGORY.INSPECTIONS]: {
    tableName: TABLE_NAMES.INSPECTIONS,
    tableContentName: "inspections",
  },
  [SUBMISSION_CATEGORY.PERMITTING]: {
    tableName: TABLE_NAMES.PERMITS,
    tableContentName: "permits",
  },
};

const SubmissionsTable = ({
  category,
  initialQueryDescription,
}: {
  category: SUBMISSION_CATEGORY.INSPECTIONS | SUBMISSION_CATEGORY.PERMITTING;
  initialQueryDescription: QueryDescription<SyntheticFieldDescription[]>;
}) => {
  const { tableContentName, tableName } = SUBMISSIONS_CATEGORY_MAP[category];

  const location = useLocation();

  const { user, admin } = useContext(AuthContext);

  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetSubmissionTableConfigQuery({
      variables: {
        category,
      },
      fetchPolicy: "network-only",
    });

  const [
    querySubmissions,
    { previousData, data: currentData, networkStatus, loading, error, refetch },
  ] = useGetQuerySubmissionsLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const actionsColumn: ColumnDef<QuerySubmissionTableResult> = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      const submission = {
        hasSummary: row.original[`${tableName}.hasSummary`],
        id: row.original[`${tableName}.id`],
        status: row.original["CertificateUploads.status"],
        property: {
          id: row.original["Properties.id"],
          streetAddress: row.original["Properties.streetAddress"],
          longitude: row.original["Properties.longitude"],
          latitude: row.original["Properties.latitude"],
        },
        submissionTypeVersion: {
          submissionType: {
            category: row.original[`${tableName}.submissionTypeCategory`],
            name: row.original[`${tableName}.submissionTypeName`],
          },
        },
      };
      return (
        <ActionButton
          submission={{ ...submission, category }}
          onUpdate={refetch!}
        />
      );
    },
  };

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    setQueryDescriptionInURL,
    generatedColumnDefinitions,
  } = useQueryDescription<QuerySubmissionTableResult>({
    initialQueryDescription,
    tableConfig: columnConfigResponse?.getSubmissionTableConfig.data || [],
    //we're reusing the custom cells from the files table for the certificate upload status column
    customTableCells: FILES_TABLE_CUSTOM_CELLS,
    defaultSort: [{ id: `${tableName}.createdAt`, desc: true }],
  });

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname: location.pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: [
          ...initialQueryDescription.fields.map(field => fieldId(field)),
          ACTION_COLUMN_DEF_CONSTANTS.id,
        ],
        columnSizing: {},
        sorting: [],
      },
    });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.querySubmissions.pageInfo.totalPages,
    previousTotalPages: previousData?.querySubmissions.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<
    ColumnDef<QuerySubmissionTableResult>
  > = [...generatedColumnDefinitions];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (
    loadingColumnConfig ||
    !columnConfigResponse?.getSubmissionTableConfig.data
  ) {
    return null;
  }

  const loadingDetails = {
    loading,
    loadingText: `Loading ${tableContentName}`,
    noDataText: `No ${tableContentName} found`,
  };

  const requiredFields = [
    { table: tableName, name: "id" },
    { table: TABLE_NAMES.PROPERTIES, name: "id" },
    { table: TABLE_NAMES.PROPERTIES, name: "latitude" },
    { table: TABLE_NAMES.PROPERTIES, name: "longitude" },
    { table: tableName, name: "hasSummary" },
  ];

  const search = ({ page }: { page: number }) => {
    const variables: GetQuerySubmissionsQueryVariables = {
      description: {
        table: tableName,
        fields: mergeFieldGroups(queryDescription.fields, requiredFields),

        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void querySubmissions({
      variables,
    });
  };

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: initialQueryDescription.fields.map(field =>
      fieldId(field)
    ),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = initialTableState.fields.map(field => {
    return tanstackColumnDefinitions.find(
      column => column.id === fieldId(field)
    )!;
  });

  initialColumns.push(actionsColumn);

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  const tableActions = <ExportDataButton />;

  return (
    <FullWidthTable<QuerySubmissionTableResult, Array<FilterDescription>>
      actions={tableActions}
      columns={initialColumns}
      previousData={previousData?.querySubmissions.data}
      currentData={data?.querySubmissions.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.querySubmissions.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      filterable={{
        newFilterConfiguration: columnConfigResponse.getSubmissionTableConfig
          .data as Array<Attribute>,
        search,
      }}
      columnSettingProps={{
        columnConfiguration: columnConfigResponse.getSubmissionTableConfig.data,
        columnDefinitions: tanstackColumnDefinitions,
      }}
      timeoutError={timeoutError}
      queryDescription={queryDescription}
      updateQueryDescription={updateQueryDescription}
      setQueryDescriptionInURL={setQueryDescriptionInURL}
    />
  );
};

export const InspectionsTable = () => {
  const initialQueryDescription = {
    table: TABLE_NAMES.INSPECTIONS,
    fields: [
      { table: TABLE_NAMES.INSPECTIONS, name: "submissionTypeName" },
      { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
      { table: TABLE_NAMES.PROPERTIES, name: "city" },
      { table: TABLE_NAMES.PARCELS, name: "parcelId" },
      { table: TABLE_NAMES.INSPECTIONS, name: "createdAt" },
    ],
    filters: [],
    orderBy: {
      table: TABLE_NAMES.INSPECTIONS,
      field: "createdAt",
      direction: "DESC" as const,
    },
  };

  return (
    <SubmissionsTable
      category={SUBMISSION_CATEGORY.INSPECTIONS}
      initialQueryDescription={initialQueryDescription}
    />
  );
};

export const PermitsTable = () => {
  const initialQueryDescription = {
    table: TABLE_NAMES.PERMITS,
    fields: [
      { table: TABLE_NAMES.PERMITS, name: "submissionTypeName" },
      { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
      { table: TABLE_NAMES.PROPERTIES, name: "city" },
      { table: TABLE_NAMES.PARCELS, name: "parcelId" },
      { table: TABLE_NAMES.CERTIFICATE_UPLOADS, name: "status" },
      { table: TABLE_NAMES.PERMITS, name: "createdAt" },
    ],
    filters: [],
    orderBy: {
      table: TABLE_NAMES.PERMITS,
      field: "createdAt",
      direction: "DESC" as const,
    },
  };

  return (
    <SubmissionsTable
      category={SUBMISSION_CATEGORY.PERMITTING}
      initialQueryDescription={initialQueryDescription}
    />
  );
};
