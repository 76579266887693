import React, { useContext } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { SettingsRow } from "./SettingsRow";
import { useColumnSettingsReducer } from "./ColumnSettingsReducer";
import { Attribute } from "../../types";
import { TableContext } from "../../TableContext";
import { track } from "../../../../../utils/tracking";
import Divider from "../../../Divider";
import { AddMoreWrapper } from "../../__styles__/ViewSettings";

import { AttributesWrapper, DropdownItem } from "../__styles__/TableSettings";

const EditColumnsMenu = <T,>({
  editableColumns,
  dispatch,
  hideColumn,
  columnConfig,
}: {
  editableColumns: ColumnDef<T>[];
  dispatch: ReturnType<typeof useColumnSettingsReducer>[1];
  hideColumn: (id: string) => void;
  columnConfig: Array<Attribute>;
}) => {
  const { name } = useContext(TableContext);

  const hasEditableColumns = editableColumns.length > 0;

  return (
    <>
      {hasEditableColumns && (
        <DropdownItem>
          <AttributesWrapper>
            {editableColumns.map(column => (
              <SettingsRow
                label={column!.header as string}
                key={column.id}
                rightIcon={"x"}
                onRightIconClick={() => {
                  hideColumn(column.id!);
                  track("Removed column", {
                    tableName: name,
                    column: column!.header as string,
                  });
                }}
                testId={`edit-column-${column.id}`}
                omitHoverStyles={true}
              />
            ))}
          </AttributesWrapper>
        </DropdownItem>
      )}
      <Divider />
      <AddMoreWrapper>
        <SettingsRow
          label={"Add column"}
          leftIcon={"plus"}
          rightIcon={"chevron-right"}
          onRowClick={() =>
            dispatch({
              type: "setAttribute",
              data: {
                newAttribute: {
                  attributes: columnConfig,
                  label: "Category",
                },
              },
            })
          }
        />
      </AddMoreWrapper>
    </>
  );
};

export default EditColumnsMenu;
