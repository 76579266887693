import React, { useContext, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { isEmpty, startCase } from "lodash";
import { useLocation } from "react-router";

import Badge from "../Common/Badge";
import TypeBadge from "./TypeBadge";
import { ActionButton } from "./ActionButton";
import { FullWidthTable } from "../Common/FullWidthTable/FullWidthTable";
import { LogsQuery } from "../../generated/graphql";
import { formatDateString } from "../../utils/dates";
import { getCurrentURL } from "../../utils/url";
import { useLegacyTableStateInURL } from "../Common/Tables/hooks";

import { NASpan } from "./__styles__/Table";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../Common/ActionCell";
import { sanityCheckLocalColumnOrder } from "../Common/FullWidthTable/utils";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../hooks/useTableDisplayConfig";
import { AuthContext } from "../Authorization/AuthContext";

const LOADING_TEXT = "Loading logs";
const NO_DATA_TEXT = "No logs found";

const NA = () => <NASpan>N/A</NASpan>;

export type InformationRequestTableDisplay =
  LogsQuery["informationRequests"][number];

export default ({
  data,
  loading,
  onUpdate,
  tableActions,
}: {
  data: InformationRequestTableDisplay[];
  loading: boolean;
  onUpdate: () => void;
  tableActions?: React.ReactNode;
}) => {
  const location = useLocation();
  const { user, admin } = useContext(AuthContext);

  const columns = useMemo<Array<ColumnDef<InformationRequestTableDisplay>>>(
    () => [
      {
        id: "date",
        header: "Date",
        accessorKey: "submittedAt",
        size: 400,
        cell: row =>
          formatDateString({
            format: "MM/DD/YYYY",
            dateString: `${row.row.original.submittedAt}T00:00:00.000`,
          }),
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        cell: row => <TypeBadge type={row.row.original.type} />,
      },
      {
        id: "address",
        header: "Address",
        accessorKey: "address",
        cell: ({ row }) => {
          const { address } = row.original;
          return isEmpty(address) ? <NA /> : address;
        },
      },
      {
        id: "city",
        header: "City",
        accessorFn: row => row.property?.city,
        cell: row => row.row.original.property?.city ?? <NA />,
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        cell: row => (
          <Badge text={startCase(row.row.original.status)} color={"black"} />
        ),
      },
      {
        ...ACTION_COLUMN_DEF_CONSTANTS,
        cell: row => (
          <ActionButton
            infoRequest={row.row.original}
            isDetailView={false}
            prevLocation={getCurrentURL(location)}
            onUpdate={onUpdate}
          />
        ),
      },
    ],
    []
  );

  const [initialTableState, setTableStateInURL] = useLegacyTableStateInURL({
    defaultSortingParams: [{ id: "date", desc: true }],
  });

  const pathname = location.pathname;

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: columns.map(column => column.id!),
        columnSizing: {},
        sorting: [],
      },
    });

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: columns.map(column => column.id!),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = updatedColumnOrder
    ? updatedColumnOrder.map(id => columns.find(c => c.id === id)!)
    : localColumnOrder.map((id: string) => columns.find(c => c.id === id)!);

  return (
    <FullWidthTable
      actions={tableActions}
      currentData={data}
      columns={initialColumns}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      loadingDetails={{
        loading,
        loadingText: LOADING_TEXT,
        noDataText: NO_DATA_TEXT,
      }}
      initialState={initialTableState}
      setTableStateInURL={setTableStateInURL}
    />
  );
};
